<template>
    <v-container>
        <!--v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn-->
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.identifier == null ? 'Erstellen' : `'${editItem.identifier}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form v-model="editValid">
                        <v-container>
                            <v-row>
                                <!--v-col cols="4">
                                    <v-card tile color="box_accent" class="px-2 pt-2">
                                        <v-img height="250px" :src="image_preview" contain />
                                    </v-card>
                                </v-col-->
                                <v-col>
                                    <axz-file-picker v-model="editItem.identifier" :rules="file_rules" label="Datei" disabled></axz-file-picker>
                                </v-col>
                            </v-row>
                            <!--v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.identifier == null" @click="create()" :loading="editLoading" :disabled="!editValid">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading" :disabled="!editValid">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row-->
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Files
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols" :items="$db.files.data" :items-per-page="-1" dense :hide-default-footer="true" :loading="loading">
                        <template v-slot:item.internal="{ item }">
                            <v-icon v-if="item.internal == 1" small color="grey" @click="setInternal(item, false)">fas fa-lock</v-icon>
                            <v-icon v-else small @click="setInternal(item, true)">fas fa-unlock</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols: [
                //{ text: 'Intern', value: 'internal' },
                { text: 'Hash', value: 'identifier' },
                { text: 'Typ', value: 'type' },
                { text: 'Größe', value: 'size' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
            ],
            media: [],
            selected: 0,
            editItem: null,
            editValid: false,
            editLoading: false,
            file_rules: [
                value => !value || value.size < 20000000 || 'File size should be less than 20 MB!',
            ],
        }
    },
    computed: {
        image_preview () {
            if(this.editItem.data != null && (this.editItem.data.type == 'image/png' || this.editItem.data.type == 'image/jpeg')){
                return URL.createObjectURL(this.editItem.data);
            }
            else return null;
        }
    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                //this.loadMedia(); //or clone edit item !=??!?!?
            }
        }
    },
    methods: {
        edit(item) {
            if(item != null) {
                this.editItem = Object.assign(item);
                this.editItem.data = { name: this.editItem.type, /*type: this.editItem.type,*/ size: this.editItem.size };
            }
            else {
                this.editItem = {
                    identifier: null,
                    data: null,
                    internal: 0,
                }
            }
            this.selected = 1;
        },
        /*setInternal(item, state) {
            this.$db.files.setInternal(item.identifier, state ? 1 : 0).then(this.$db.files.load);
        },*/
        create(){
            /*this.editLoading = true;
            this.$db.files.create(this.editItem.data, this.editItem.internal).then((identifier) => {
                console.log(identifier);
                this.editLoading = false;
                this.editItem = null;
                this.$db.files.load();
            });*/
        },
        update(){
            /*this.editLoading = true;
            this.call('media', 'update', {
                ID: this.editItem.ID,
                title: this.editItem.title,
                filename: this.editItem.filename,
                image: this.editItem.image_upload != null,
                upload: this.editItem.image_upload,
                file: this.file_upload.type != null ? this.file_upload : null,
            }).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadMedia();
            });*/
        }
    },
    mounted() {
        this.$db.files.load();
    }
}
</script>